<template>
  <portal to="app-bar">
    <v-btn v-bind:to="{name: 'admin/challenges'}" class="mr-2" text>
      <span class="mr-2">Challenges</span>
      <v-icon>mdi-text-box-outline</v-icon>
    </v-btn>
    <v-btn v-bind:to="{name: 'admin/evaluations'}" class="mr-2" text>
      <span class="mr-2">Evaluations</span>
      <v-icon>mdi-chart-bar</v-icon>
    </v-btn>
    <v-btn text>
      <span v-on:click="logout" class="mr-2">Logout</span>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </portal>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    }
  }
};
</script>