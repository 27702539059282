<template>
  <v-app>
    <v-app-bar app class="white">
      <router-link v-bind:to="{name: 'challenge'}">
        <v-img max-height="56" max-width="183" position="left center" src="~@/assets/gc.svg" contain/>
      </router-link>
      <v-spacer/>
      <portal-target name="app-bar-search"/>
      <v-spacer/>
      <portal-target name="app-bar"/>
    </v-app-bar>
    <v-main class="mt-5">
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
#app {
  background: #EEEEEE;
}

th {
  color: #ED5120 !important;
  caret-color: #ED5120 !important;
}

.v-data-table-header > tr:last-child > th {
  border-bottom: thin solid #ED5120 !important;
}
</style>

<script>
import axios from 'axios';

export default {
  created() {
    axios.interceptors.request.use(config => {
      if (this.$store.getters.token !== null) {
        config.headers['x-access-token'] = this.$store.getters.token;
      }

      return config;
    });

    axios.interceptors.response.use(response => response, error => {
      if (error.response.status === 401) {
        this.$store.dispatch('logout');
      }

      return Promise.reject(error);
    });
  }
};
</script>