<template>
  <v-container>
    <app-bar/>

    <v-data-table v-bind:headers="headers" v-bind:items="items" v-bind:loading="loading" class="w mx-auto" disable-sort disable-pagination hide-default-footer>
      <template slot="progress">
        <v-progress-linear color="deep-orange" indeterminate/>
      </template>
      <template v-slot:item.number="{item, index}">{{ ++index }}</template>
      <template v-slot:item.createdAt="{ item }">{{ item.createdAt | date }} <span class="text--disabled">Uhr</span></template>
    </v-data-table>
  </v-container>
</template>

<style>
.w {
  max-width: 700px;
}
</style>

<script>
import AppBar from '@/components/AppBar';

export default {
  components: {
    AppBar
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          text: '#',
          value: 'number'
        }, {
          text: 'Date',
          value: 'createdAt'
        }, {
          text: 'Name',
          value: 'userName'
        }, {
          align: 'end',
          text: 'Words per minute',
          value: 'score'
        }
      ],
      evaluations: []
    };
  },
  computed: {
    items() {
      const items = [];

      this.evaluations.forEach(item => {
        items.push(Object.assign(item, {
          score: this.score(item)
        }));
      });

      const result = [];
      const map = new Map();

      for (const item of items.filter(item => item.score > 0).sort((x, y) => x.score > y.score ? -1 : 1)) {
        if (!map.has(item.userName)) {
          map.set(item.userName, true);
          result.push(item);
        }
      }
      return result.slice(0, 30);
    }
  },
  methods: {
    score(item) {
      return Math.round(((item.userCharacterTyped / 5) - item.userErrors) / (item.userTimeElapsed / 60));
    },
    load() {
      this.loading = true;
      this.$store.dispatch('evaluations/read', {
        public: true,
        $select: [
          'userName',
          'userCharacterTyped',
          'userErrors',
          'userTimeElapsed',
          'createdAt'
        ]
      }).then(evaluations => {
        this.evaluations = evaluations;
      }).catch(e => console.error(e)).finally(() => this.loading = false);
    }
  }, mounted() {
    this.load();
  }
};
</script>