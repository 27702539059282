<template>
  <v-container>
    <admin-app-bar/>
    <portal to="app-bar-search">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" color="deep-orange" single-line hide-details style="min-width: 500px"/>
    </portal>

    <v-data-table v-on:click:row="detail" v-bind:headers="headers" v-bind:items="evaluations" v-bind:loading="loading" v-bind:search="search" class="pointer" v-bind:items-per-page="50" v-bind:footer-props="{'items-per-page-options': [25, 50, 100, {'text': 'all', 'value': -1}]}" multi-sort>
      <template slot="progress">
        <v-progress-linear color="deep-orange" indeterminate/>
      </template>
      <template v-slot:item.completed="{ item }">{{ item.completed }}<span class="text--disabled">%</span></template>
      <template v-slot:item.userAccuracy="{ item }">{{ item.userAccuracy }}<span class="text--disabled">%</span></template>
      <template v-slot:item.public="{ item }">
        <v-checkbox v-on:click.stop="setPublic(item)" v-model="item.public" color="deep-orange"/>
      </template>
      <template v-slot:item.createdAt="{ item }">{{ item.createdAt | date }} <span class="text--disabled">Uhr</span></template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" v-on:click.stop="destroy(item)">mdi-delete</v-icon>
          </template>
          <span>Delete challenge</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <delete v-bind:item="item.delete" v-on:deleted="load"/>

    <v-dialog v-model="dialog" max-width="1200px">
      <v-card>
        <v-card-title>{{ item.detail.userName }}</v-card-title>
        <v-card-subtitle>{{ item.detail.createdAt | date }} <span class="text--disabled">Uhr</span></v-card-subtitle>
        <v-card-text>
          <div v-html="spanned"/>
          <v-divider/>
          {{ item.detail.userText }}
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn v-on:click="dialog = false" color="deep-orange--text">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-layout class="fab-container pr-3 pt-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="deep-orange" elevation="2" fab>
            <xlsx-workbook>
              <xlsx-sheet v-bind:collection="filtered" sheet-name="Evaluations"/>
              <xlsx-download>
                <v-icon color="white">mdi-microsoft-excel</v-icon>
              </xlsx-download>
            </xlsx-workbook>
          </v-btn>
        </template>
        <span>Export as xlsx</span>
      </v-tooltip>
    </v-layout>
  </v-container>
</template>

<style>
.pointer tr > td {
  cursor: pointer;
}
</style>

<style scoped>
.fab-container {
  position: fixed;
  top: 64px;
  right: 0;
}
</style>

<script>
import AdminAppBar from '@/components/admin/AppBar';
import Delete from './delete';
import {XlsxWorkbook, XlsxSheet, XlsxDownload} from "vue-xlsx";
import {format} from "date-fns";

export default {
  components: {
    AdminAppBar,
    Delete,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
      loading: false,
      search: '',
      headers: [
        {
          text: 'Username',
          value: 'userName'
        }, {
          text: 'Challenge Name',
          value: 'challengeName'
        }, {
          text: 'Completed',
          value: 'completed'
        }, {
          text: 'Accuracy',
          value: 'userAccuracy'
        }, {
          text: 'Errors',
          value: 'userErrors'
        }, {
          text: 'CPM',
          value: 'userCpm'
        }, {
          text: 'WPM',
          value: 'userWpm'
        }, {
          text: 'Time Elapsed',
          value: 'userTimeElapsed'
        }, {
          text: 'Challenge Time',
          value: 'challengeTime'
        }, {
          text: 'Public',
          value: 'public'
        }, {
          text: 'Date',
          value: 'createdAt'
        },
        {
          align: 'end',
          text: '',
          value: 'actions'
        }
      ],
      evaluations: [],
      item: {
        delete: {},
        detail: {}
      },
      dialog: false
    };
  },
  watch: {
    "item.detail"() {
      this.dialog = true;
    }
  },
  methods: {
    setPublic(item) {
      this.$store.dispatch('evaluations/update', {
        _id: item._id,
        public: item.public
      }).catch(e => console.error(e));
    },
    detail(item) {
      this.item.detail = {...item}
    },
    destroy(item) {
      this.item.delete = {...item}
    },
    load() {
      this.loading = true;
      this.$store.dispatch('evaluations/read', {
        $sort: {
          createdAt: -1
        }
      }).then(evaluations => {
        evaluations.forEach(function (evaluation) {
          evaluation.completed = Math.round((evaluation.userText.length * 100) / evaluation.challengeText.length);
        });

        this.evaluations = evaluations
      }).catch(e => console.error(e)).finally(() => this.loading = false);
    }
  },
  computed: {
    spanned() {
      let data = '';

      const array = (this.item.detail.userText || '').split('');

      (this.item.detail.challengeText || '').split('').forEach(function (char) {
        data += '<span>' + char + '</span>';
      });

      const parser = new DOMParser();
      const doc = parser.parseFromString(data, "text/html");

      doc.querySelectorAll('span').forEach((span, index) => {
        if (array[index] == null) {
          span.classList.add('text--disabled');
        } else if (array[index] === span.innerText) {
          span.classList.add('green--text');
        } else {
          span.classList.add('red--text');
          span.classList.add('text-decoration-underline');
        }
      });

      return doc.getElementsByTagName('body')[0].innerHTML;
    },
    filtered() {
      const data = [];

      this.evaluations.forEach(item => {
        data.push({
          'Username': item.userName,
          'Challenge Name': item.challengeName,
          'Completed': item.completed,
          'Accuracy': item.userAccuracy,
          'Errors': item.userErrors,
          'CPM': item.userCpm,
          'WPM': item.userWpm,
          'Time Elapsed': item.userTimeElapsed,
          'Challenge Time': item.challengeTime,
          'Date': format(new Date(item.createdAt), 'dd.MM.yyyy'),
          'Time': format(new Date(item.createdAt), 'HH:mm:ss'),
          'Challenge Text': item.challengeText,
          'Typed Text': item.userText,
        });
      });

      return data.length === 0 ? [{}] : data;
    }
  },
  mounted() {
    this.load();
  }
};
</script>