<template>
  <v-dialog v-model="visible" max-width="500px">
    <v-card v-bind:loading="loading">
      <template slot="progress">
        <v-progress-linear color="deep-orange" indeterminate/>
      </template>
      <v-card-title>{{ item.userName }}</v-card-title>
      <v-card-subtitle>{{ item.createdAt | date }} <span class="text--disabled">Uhr</span></v-card-subtitle>
      <v-card-text>Are you sure you want to delete this evaluation?</v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-on:click="visible = false">Cancel</v-btn>
        <v-btn v-on:click="save" color="deep-orange--text">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    };
  },
  watch: {
    item() {
      if (Object.keys(this.item).length === 0) {
        return;
      }

      this.visible = true;
    }
  },
  methods: {
    save() {
      this.loading = true;

      this.$store.dispatch('evaluations/delete', this.item).then(() => {
        this.$emit('deleted');
        this.visible = false;
      }).catch(e => console.error(e)).finally(() => this.loading = false);
    }
  }
};
</script>