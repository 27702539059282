<template>
  <portal to="app-bar">
    <template v-if="$route.name !== 'top'">
      <v-btn v-on:click="$emit('reset')" class="mr-2" text>
        <span class="mr-2">Reset</span>
        <v-icon>mdi-reload</v-icon>
      </v-btn>
      <v-btn v-bind:to="{name: 'top'}" class="mr-2" text>
        <span class="mr-2">Top List</span>
        <v-icon>mdi-format-list-numbered</v-icon>
      </v-btn>
      <v-btn v-on:click="logout" text>
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
    </template>
    <template v-else-if="$route.name === 'top'">
      <v-btn v-bind:to="{name: 'challenge'}" text>
        <span class="mr-2">Challenges</span>
        <v-icon>mdi-typewriter</v-icon>
      </v-btn>
    </template>
  </portal>
</template>

<script>
export default {
  methods: {
    logout() {
      sessionStorage.removeItem('username');
      this.$emit('loggedOut', null);
    }
  }
};
</script>