import Vue from 'vue'
import Vuex from 'vuex'

import qs from 'qs'

import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state() {
        return {
            token: null
        };
    },
    getters: {
        token: state => state.token
    },
    mutations: {
        token(state, token) {
            state.token = token;
        }
    },
    actions: {
        login({commit}, params) {
            return new Promise(function (resolve, reject) {
                axios.post(process.env.VUE_APP_API_URL + 'auth/signin', params).then(function (response) {
                    if (response.data.token === undefined) {
                        return reject({data: {message: 'missing token'}});
                    }

                    commit('token', response.data.token);

                    localStorage.setItem('token', response.data.token);

                    resolve(response.data);
                }).catch(function (error) {
                    commit('token', null);
                    reject(error.response);
                });
            });
        },
        logout({commit}) {
            commit('token', null);

            localStorage.removeItem('token');
        }
    },
    modules: {
        evaluations: {
            namespaced: true,
            state: () => ({
                model: 'evaluations'
            }),
            getters: {
                model: (state) => state.model
            },
            actions: {
                create({getters}, params) {
                    return new Promise(function (resolve, reject) {
                        axios.post(process.env.VUE_APP_API_URL + getters.model, params).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                },
                read({getters}, params = {}) {
                    return new Promise(function (resolve, reject) {
                        axios.get(process.env.VUE_APP_API_URL + getters.model, {
                            params,
                            paramsSerializer: params => qs.stringify(params, {
                                encode: false
                            })
                        }).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                },
                update({getters}, params) {
                    return new Promise(function (resolve, reject) {
                        if (params._id === undefined) {
                            reject('missing object._id');
                        }

                        const id = params._id;

                        delete params._id;
                        delete params.__v;
                        delete params.createdAt;
                        delete params.updatedAt;

                        axios.patch(process.env.VUE_APP_API_URL + getters.model + '/' + id, params).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                },
                delete({getters}, params) {
                    return new Promise(function (resolve, reject) {
                        if (params._id === undefined) {
                            reject('missing object._id');
                        }

                        axios.delete(process.env.VUE_APP_API_URL + getters.model + '/' + params._id, params).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                }
            }
        },
        challenges: {
            namespaced: true,
            state: () => ({
                model: 'challenges'
            }),
            getters: {
                model: (state) => state.model
            },
            actions: {
                create({getters}, params) {
                    return new Promise(function (resolve, reject) {
                        axios.post(process.env.VUE_APP_API_URL + getters.model, params).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                },
                read({getters}, params = {}) {
                    return new Promise(function (resolve, reject) {
                        axios.get(process.env.VUE_APP_API_URL + getters.model, {
                            params,
                            paramsSerializer: params => qs.stringify(params, {
                                encode: false
                            })
                        }).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                },
                update({getters}, params) {
                    return new Promise(function (resolve, reject) {
                        if (params._id === undefined) {
                            reject('missing object._id');
                        }

                        const id = params._id;

                        delete params._id;
                        delete params.__v;
                        delete params.createdAt;
                        delete params.updatedAt;

                        axios.patch(process.env.VUE_APP_API_URL + getters.model + '/' + id, params).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                },
                delete({getters}, params) {
                    return new Promise(function (resolve, reject) {
                        if (params._id === undefined) {
                            reject('missing object._id');
                        }

                        axios.delete(process.env.VUE_APP_API_URL + getters.model + '/' + params._id, params).then(function (response) {
                            resolve(response.data);
                        }).catch(function (error) {
                            reject(error.response);
                        });
                    });
                }
            }
        }
    }
});