<template>
  <v-dialog v-model="visible" max-width="500px" persistent>
    <v-card>
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field v-on:keydown.enter.prevent="save" v-model="user" v-bind:rules="[v => /^[a-z\-0-9]+\.[a-z\-0-9]+/.test(v) || 'Vorname und Nachname, getrennt duch keinen Punkt, kleingeschrieben']" color="deep-orange" label="vorname.nachname" outlined/>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-on:click="save" color="deep-orange--text">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      user: null
    };
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      sessionStorage.setItem('username', this.user);

      this.$emit('loggedIn', this.user);
      this.user = null;
    }
  }
};
</script>