import App from '@/App'
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify/lib/framework';
import PortalVue from 'portal-vue'

import Challenge from '@/views/Challenge'
import AdminChallenges from '@/views/admin/challenges/read'
import AdminDashboard from '@/views/admin/Dashboard'
import AdminEvaluations from '@/views/admin/evaluations/read'
import Login from '@/views/Login';
import Top from '@/views/Top';

import "@mdi/font/css/materialdesignicons.css"
import "@fontsource/roboto"
import store from './store'

Vue.config.productionTip = false

Vue.use(PortalVue);
Vue.use(VueRouter)
Vue.use(Vuetify);

import {format} from "date-fns"

Vue.filter('date', function (value) {
    if (value === undefined) {
        return "-"
    }

    return format(new Date(value), "dd.MM.yyyy, HH:mm");
});

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'challenge',
            component: Challenge
        }, {
            path: '/login',
            name: 'login',
            component: Login
        }, {
            path: '/top',
            name: 'top',
            component: Top
        }, {
            path: '/admin',
            name: 'admin',
            component: AdminDashboard
        }, {
            path: '/admin/challenges',
            name: 'admin/challenges',
            component: AdminChallenges
        }, {
            path: '/admin/evaluations',
            name: 'admin/evaluations',
            component: AdminEvaluations
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (!['/', '/top', '/login'].includes(to.path) && store.getters.token === null) {
        next({
            name: 'login',
            query: {
                redirect: to.path
            }
        });
    } else {
        next();
    }
});

if (localStorage.getItem('token') !== null) {
    store.commit('token', localStorage.getItem('token'));
}

new Vue({
    vuetify: new Vuetify(),
    router,
    store,
    render: h => h(App)
}).$mount('#app');