<template>
  <v-container>
    <admin-app-bar/>

    Dashboard
  </v-container>
</template>

<script>
import AdminAppBar from '@/components/admin/AppBar'

export default {
  components: {
    AdminAppBar
  }
};
</script>