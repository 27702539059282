<template>
  <v-container>
    <admin-app-bar/>

    <v-card>
      <v-data-table v-bind:headers="headers" v-bind:items="challenges" v-bind:loading="loading" disable-pagination hide-default-footer>
        <template slot="progress">
          <v-progress-linear color="deep-orange" indeterminate/>
        </template>
        <template v-slot:item.enabled="{ item }">
          <v-icon v-if="item.enabled" color="deep-orange">mdi-check-circle-outline</v-icon>
          <v-icon v-else color="grey lighten-2">mdi-check-circle-outline</v-icon>
        </template>
        <template v-slot:item.characters="{ item }">{{ item.text.length }}</template>
        <template v-slot:item.words="{ item }">{{ item.text.split(' ').length }}</template>
        <template v-slot:item.createdAt="{ item }">{{ item.createdAt | date }} <span class="text--disabled">Uhr</span></template>
        <template v-slot:item.updatedAt="{ item }">{{ item.updatedAt | date }} <span class="text--disabled">Uhr</span></template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" v-on:click.stop="update(item)" class="mr-2">mdi-pencil</v-icon>
            </template>
            <span>Edit challenge</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" v-on:click.stop="destroy(item)">mdi-delete</v-icon>
            </template>
            <span>Delete challenge</span>
          </v-tooltip>
        </template>
      </v-data-table>

      <create v-on:created="load" ref="create"/>
      <delete v-bind:item="item.delete" v-on:deleted="load"/>
      <update v-bind:item="item.update" v-on:updated="load"/>
    </v-card>

    <v-layout class="fab-container pr-3 pt-3">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab color="deep-orange">
            <v-icon v-on:click="$refs.create.show()" color="white">mdi-text-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>New challenge</span>
      </v-tooltip>
    </v-layout>
  </v-container>
</template>

<style scoped>
.fab-container {
  position: fixed;
  top: 64px;
  right: 0;
}
</style>

<script>
import AdminAppBar from '@/components/admin/AppBar';
import Delete from './delete';
import Update from './update';
import Create from "@/views/admin/challenges/create";

export default {
  components: {
    AdminAppBar,
    Create,
    Delete,
    Update
  },
  data: () => ({
    loading: false,
    challenges: [],
    headers: [
      {
        text: 'Enabled',
        value: 'enabled'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Time',
        value: 'time'
      }, {
        text: 'Characters',
        value: 'characters'
      }, {
        text: 'Words',
        value: 'words'
      }, {
        text: 'Created',
        value: 'createdAt'
      }, {
        text: 'Updated',
        value: 'updatedAt'
      }, {
        align: 'end',
        value: 'actions'
      }
    ],
    item: {
      delete: {},
      update: {}
    }
  }),
  methods: {
    load() {
      this.loading = true;

      this.$store.dispatch('challenges/read', {
        $sort: {
          createdAt: -1
        }
      }).then(challenges => this.challenges = challenges).catch(e => console.error(e)).finally(() => this.loading = false);
    },
    destroy(item) {
      this.item.delete = {...item};
    },
    update(item) {
      this.item.update = {...item};
    }
  },
  mounted() {
    this.load();
  }
};
</script>