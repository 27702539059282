<template>
  <v-container class="fill-height">
    <v-card width="500px" class="mx-auto">
      <template slot="progress">
        <v-progress-linear color="deep-orange" indeterminate/>
      </template>
      <v-card-title>Admin Login</v-card-title>
      <v-card-text>
        <v-alert v-if="error !== null" type="error" dismissible>{{ error }}</v-alert>
        <v-form ref="form">
          <v-text-field v-on:keydown.enter.prevent="save" v-model="username" v-bind:rules="[v => /^.+$/.test(v) || 'username required']" color="deep-orange" label="Username" outlined/>
          <v-text-field v-on:keydown.enter.prevent="save" v-model="password" v-bind:rules="[v => /^.+$/.test(v) || 'password required']" type="password" color="deep-orange" label="Password" outlined/>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-on:click="save" color="deep-orange--text">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      error: null,
    };
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      console.log(this.$route);

      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      }).then(() => {
        this.username = '';
        this.password = '';
        this.error = null;

        this.$router.push(this.$route.query.redirect || '/');
      }).catch(error => {
        this.error = error.data.message

        this.$store.dispatch('logout');
      });
    }
  }
};
</script>