<template>
  <v-dialog v-model="visible" max-width="1200px">
    <v-card v-bind:loading="loading">
      <template slot="progress">
        <v-progress-linear color="deep-orange" indeterminate/>
      </template>
      <v-card-title>Characters: {{ (item.text || '').length }}, Words: {{ (item.text || '').split(' ').length }}</v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-text-field v-model="item.name" v-bind:rules="[v => /^.+$/.test(v) || 'name is required']" color="deep-orange" label="Name" outlined/>
          <v-text-field v-model="item.time" v-bind:rules="[v => /^\d+$/.test(v) || 'time is required']" color="deep-orange" label="Time" outlined/>
          <v-textarea v-model="item.text" v-bind:rules="[v => /^.+$/.test(v) || 'text is required']" color="deep-orange" label="Text" outlined/>
          <v-checkbox v-model="item.enabled" color="deep-orange" label="enabled"/>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-on:click="visible = false">Cancel</v-btn>
        <v-btn v-on:click="save" color="deep-orange--text">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      visible: false,
      loading: false
    };
  },
  watch: {
    item() {
      if (Object.keys(this.item).length === 0) {
        return;
      }

      this.visible = true;
    }
  },
  methods: {
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.loading = true;

      this.$store.dispatch('challenges/update', this.item).then(() => {
        this.$emit('updated');
        this.visible = false;
      }).catch(e => console.error(e)).finally(() => this.loading = false);
    }
  }
};
</script>