<template>
  <v-container>
    <app-bar v-on:reset="reset" v-on:loggedOut="setUsername"/>

    <div v-if="loading === false && Object.keys(challenge).length === 0">
      <v-row>
        <v-col>There are currently no challenges available.</v-col>
      </v-row>
    </div>
    <div v-else-if="loading === false && Object.keys(challenge).length !== 0">
      <v-card v-if="username !== null" color="transparent" flat tile>
        <v-card-text class="text-center text-h5 deep-orange--text">{{ username }}</v-card-text>
      </v-card>
      <v-card class="d-flex justify-space-around my-10" color="transparent" flat tile>
        <v-card class="rounded-circle" height="128" width="128">
          <v-card-text class="fill-height d-flex align-center justify-center">
            <div>
              <div class="text-center text-h3">{{ errors }}</div>
              <div class="deep-orange--text text-center text-uppercase">errors</div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="rounded-circle" height="128" width="128">
          <v-card-text class="fill-height d-flex align-center justify-center">
            <div>
              <div class="text-center"><span class="text-h3">{{ timeLeft }}</span><span>s</span></div>
              <div class="deep-orange--text text-center text-uppercase">time left</div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="rounded-circle" height="128" width="128">
          <v-card-text class="fill-height d-flex align-center justify-center">
            <div>
              <div class="text-center"><span class="text-h3">{{ accuracy }}</span><span>%</span></div>
              <div class="deep-orange--text text-center text-uppercase">accuracy</div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="rounded-circle" height="128" width="128">
          <v-card-text class="fill-height d-flex align-center justify-center">
            <div>
              <div class="text-center text-h3">{{ cpm }}</div>
              <div class="deep-orange--text text-center text-uppercase">cpm</div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="rounded-circle" height="128" width="128">
          <v-card-text class="fill-height d-flex align-center justify-center">
            <div>
              <div class="text-center text-h3">{{ wpm }}</div>
              <div class="deep-orange--text text-center text-uppercase">wpm</div>
            </div>
          </v-card-text>
        </v-card>
      </v-card>

      <v-card>
        <v-card-text id="quote" class="text-h6 mb-3" v-html="spanned"/>
      </v-card>

      <v-textarea v-on:keydown.once="start" v-on:input="input" v-bind:key="key" v-model="challenge.input" v-bind:disabled="finished" placeholder="The test starts as soon as you write." solo/>
      <v-checkbox v-on:click="setPublic" v-model="isPublic" class="mt-0" label="Publish the result in the public toplist" color="deep-orange"/>

      <v-card color="transparent" flat>
        <v-card-text class="text-center text--disabled">Version: {{ version }}, Build Date: {{ buildDate | date }}</v-card-text>
      </v-card>
    </div>

    <Login v-on:loggedIn="setUsername" v-bind:visible="username === null"/>
  </v-container>
</template>

<script>
import AppBar from '@/components/AppBar';
import Login from '@/components/Login';

export default {
  components: {
    AppBar,
    Login
  },
  data() {
    return {
      challenge: {},
      characterTyped: 0,
      errors: 0,
      finished: false,
      loading: true,
      key: 0,
      timeElapsed: 0,
      timeLeft: 0,
      timer: null,
      username: sessionStorage.getItem('username'),
      isPublic: false,
      version: process.env.VUE_APP_VERSION,
      buildDate: document.documentElement.dataset.buildTimestampUtc,
      item: {}
    };
  },
  methods: {
    setPublic() {
      if (this.item._id !== undefined) {
        this.$store.dispatch('evaluations/update', {
          _id: this.item._id,
          public: this.isPublic
        }).catch(e => console.error(e));
      }
    },
    start() {
      this.timer = setInterval(this.update, 1000);
    },
    update() {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.timeElapsed++;
      } else {
        this.finish();
      }
    },
    finish() {
      clearInterval(this.timer);

      this.finished = true;

      this.$store.dispatch('evaluations/create', {
        userName: this.username,
        userCharacterTyped: this.characterTyped,
        userErrors: this.errors,
        userTimeElapsed: this.timeElapsed,
        userTimeLeft: this.timeLeft,
        userAccuracy: this.accuracy,
        userCpm: this.cpm,
        userWpm: this.wpm,
        userText: this.challenge.input,
        challengeName: this.challenge.name,
        challengeText: this.challenge.text,
        challengeTime: this.challenge.time,
        public: this.isPublic
      }).then(item => this.item = item).catch(e => console.error(e));
    },
    input() {
      const array = this.challenge.input.split('');

      this.characterTyped++;

      this.errors = 0;

      document.getElementById('quote').querySelectorAll('span').forEach((span, index) => {
        if (array[index] == null) {
          span.classList.remove('green--text');
          span.classList.remove('red--text');
          span.classList.remove('text-decoration-underline');
        } else if (array[index] === span.innerText) {
          span.classList.add('green--text');
          span.classList.remove('red--text');
          span.classList.remove('text-decoration-underline');
        } else {
          span.classList.add('red--text');
          span.classList.add('text-decoration-underline');
          span.classList.remove('green--text');

          this.errors++;
        }
      });

      if (this.challenge.input.length === this.challenge.text.length) {
        this.finish();
      }
    },
    reset() {
      this.loading = true

      clearInterval(this.timer);

      this.characterTyped = 0;
      this.errors = 0;
      this.finished = false;
      this.key++;
      this.timeElapsed = 0;
      this.timeLeft = 0;
      this.item = {};

      this.$store.dispatch('challenges/read', {
        enabled: true
      }).then(challenges => {
        this.challenge = challenges[Math.floor(Math.random() * challenges.length)] || {}
        this.timeLeft = this.challenge.time || '-';

        setTimeout(() => {
          if (document.getElementsByTagName('textarea').length !== 0) {
            document.getElementsByTagName('textarea')[0].focus()
          }
        }, 200);
      }).catch(e => console.error(e)).finally(() => this.loading = false);
    },
    setUsername(username) {
      this.username = username;
      this.reset();
    }
  },
  computed: {
    spanned() {
      let data = '';

      (this.challenge.text || {text: ''}).split('').forEach(function (char) {
        data += '<span>' + char + '</span>';
      });

      return data;
    },
    accuracy() {
      const data = Math.round((this.characterTyped - this.errors) / this.characterTyped * 100) || '-';

      return isNaN(data) || !isFinite(data) ? '-' : data;
    },
    cpm() {
      const data = Math.round((this.characterTyped / this.timeElapsed) * 60);

      return isNaN(data) || !isFinite(data) ? '-' : data;
    },
    wpm() {
      const data = Math.round(((this.characterTyped / 5) / this.timeElapsed) * 60) || '-';

      return isNaN(data) || !isFinite(data) ? '-' : data;
    }
  },
  mounted() {
    this.reset();
  }
};
</script>